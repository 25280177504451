import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { color, breakpoints, border, font } from '../../../utils/style';
import { useModalOpen } from '../../shared/Modal';
import Rotate from '../../shared/Rotate';
import { StoreContext } from '../../../store';
import PlayForFreeGames from './PlayForFreeGames';
import GameImg from './Images';
import { freespinIframeUrl } from '../../../utils/api/freespin';

const Wrapper = styled('div')`
  .green-button {
    margin: 10px 0;
    svg {
      margin-right: 7px;
    }
  }
  .iframe-container-game {
    position: relative;
    overflow: hidden;
    padding-top: 74.025%;
    clear: both;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
  .login-btn {
    height: 44px;
    width: 102px;
    padding: 0 8px;
    border-radius: ${border.borderRadius4};
    border: 1px solid ${color.pink};
    background: none;
    transition-duration: 0.4s;
    font-size: 16px;
    font-family: ${font.default};
    font-weight: 400;
    color: ${color.pink};
    cursor: pointer;
    text-transform: capitalize;
    transition-duration: 0.4s;
    &:hover {
      background-color: ${color.pink};
      color: ${color.black};
    }
  }
  .not-logged-in {
    position: relative;
    .button {
      position: absolute;
      z-index: 1;
      top: 3%;
      left: 50%;
      margin-left: -60px;
      box-shadow: 2px 2px 7px 10px rgba(0, 0, 0, 0.5);
    }
  }

  @media (min-width: ${breakpoints.mdMin}) and (max-width: ${breakpoints.xl}) {
    padding: 0 10%;
  }

  .mobile-portrait {
    display: none;
    background: ${color.trueBlack};
    margin-top: 50px;
    padding: 50px 20px;
    text-align: center;

    p {
      margin-bottom: 15px;
      text-align: center;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    .mobile-landscape {
      display: none;
    }
    .mobile-portrait {
      display: block;
    }
    &.landscape-mode {
      .mobile-portrait {
        display: none;
      }
      .mobile-landscape {
        display: block;
        position: fixed;
        z-index: 9999999 !important;
        width: 100vw;
        top: 0;
        left: 0;
        .iframe-container-game {
          width: auto;
          position: static;
          overflow: auto;
          padding-top: 0;
          iframe {
            position: static;
            width: 100vw;
            height: 100vh;
          }
        }
      }
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .mobile-portrait {
      margin-top: 0;
    }
  }
`;

const Iframe = ({ iframeUrl }) => <iframe title="game-iframe-home" scrolling="no" src={iframeUrl} />;

const LoginButton = () => {
  const loginFormOpen = useModalOpen('login-popup-window');
  return (
    <button type="button" className="button login-btn" onClick={loginFormOpen}>
      <span>เข้าสู่ระบบ</span>
    </button>
  );
};
const NoLoggedIn = ({ children }) => (
  <div className="not-logged-in">
    <LoginButton />
    {children}
  </div>
);

// REFACTOR
// Need to use library or implement HOC with loading subscriber and loading component instead of this
const Loader = () => <div>Loading...</div>;

// REFACTOR
const GameIframe = ({ game_code }) => {
  const [state] = useContext(StoreContext);
  const { user: { userName } } = state;
  const [gameUrl, setGameUrl] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const pureMQL = window.matchMedia('(orientation: landscape)');
    const gameWrapper = document.getElementById('game-page-wrap');
    pureMQL.addListener((m) => {
      if (m.matches) {
        gameWrapper.classList.add('landscape-mode');
      } else {
        gameWrapper.classList.remove('landscape-mode');
      }
    });
    freespinIframeUrl(game_code)
      .then((json) => {
        if (json && json.url) setGameUrl(json.url);
      })
      .catch((err) => {
        // console.log('Get Iframe URL request: ', err);
      });
  }, [userName, game_code]);

  return (
    <Wrapper id="game-page-wrap">
      <Rotate />
      {userName && (
        <div className="logged-in-user" id="logged-in-user">
          {gameUrl ? (
            <div className="mobile-landscape">
              <div className="iframe-container-game">
                <Iframe iframeUrl={gameUrl} />
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      )}
      {!userName && (
        <NoLoggedIn>
          <GameImg imgType={game_code} />
        </NoLoggedIn>
      )}
      <PlayForFreeGames exclCode={game_code} />
    </Wrapper>
  );
};

export default GameIframe;
