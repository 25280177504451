import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { breakpoints, color, resetButton } from '../../../utils/style';
import Balance from '../../Auth/Balance';
import { StoreContext } from '../../../store';
import IcomoonIcon from '../../shared/IcomoonIcon';
// import { invokePopup } from '../../shared/PopupWindow';
import dictionary from '../../../utils/dictionary';
import { setUser } from '../../../store/actions';
import { freespinLogout } from '../../../utils/api/freespin';
// import { useModalOpen } from '../../shared/Modal';

const HeaderWrapper = styled('div')`
  .header-games {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 10px;

    & h1 {
      margin-bottom: 0;
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 26px;
      font-weight: bold;
      line-height: normal;
      color: ${color.lightningYellow};
    }

    .auth-wrapper {
      display: flex;
      justify-content: flex-end;
      width: max-content;

      .user-balance {
        min-width: 145px;
        height: 44px;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: ${color.blue};
        background-color: ${color.purple};
        border-radius: 5px;
        margin-right: 16px;
      }
    }
  }
  .account-accordion {
    border-radius: 4px;
    height: 44px;
    z-index: 99;
    background-color: ${color.bunting};

    .accordion-title {
      width: 186px;
      height: 44px;
      cursor: pointer;
      padding: 0 20px;
      user-select: none;

      object-fit: contain;
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;

      display: flex;
      align-items: center;
      justify-content: space-between;

      & .arrow {
        transform: rotate(-180deg);

        &.open {
          transform: rotate(0deg);
        }
      }
    }

    .accordion-content {
      position: relative;
      height: 100%;
      padding: 0;
      z-index: 999;

      .account-menu {
        position: absolute;
        width: 100%;
        top: 42px;
        left: 0;
        list-style: none;
        user-select: none;
        padding-left: 0;

        & > li {
          width: 186px;
          height: 51px;
          background-color: ${color.bunting};

          display: flex;
          align-items: center;
          justify-content: center;

          border-bottom: 1px solid ${color.transparentWhite2};

          &:last-child {
            border-bottom: none;
          }

          &:hover {
            & > * {
              background-color: rgba(255, 255, 255, 0.1);
            }
          }

          & * {
            ${resetButton};
            object-fit: contain;
            font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
            font-size: 15px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: ${color.white};
            cursor: pointer;

            width: 100%;
            height: 100%;
            padding-left: 20px;

            display: flex;
            align-items: center;
          }
        }
      }

      &.accordion-open {
        display: block;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    .header-games {
      h1 {
        margin: 0 auto 20px auto;
      }

      .auth-wrapper {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .header-games {
      h1 {
        margin: 0 0 20px 0;
      }
      .auth-wrapper {
        margin-bottom: 20px;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .header-games {
      padding: 30px 0 0 0;
    }
  }
`;

const HeaderAccount = ({ page_title }) => {
  const [isOpen, setOpen] = useState(false);

  const [store, dispatch] = useContext(StoreContext);
  const {
    user: { userName },
  } = store;

  const handleChange = () => {
    setOpen(!isOpen);
  };

  const logOut = () =>
    freespinLogout(() => {
      setUser(dispatch, '');
    });

  // const openWithdraw = useModalOpen('withdraw-popup');

  return (
    <HeaderWrapper>
      <header className="header-games">
        <h1>{page_title}</h1>
        {userName && (
          <div className="auth-wrapper">
            <Balance />
            <div className="accordion account-accordion">
              <div className="accordion-content">
                <div className="accordion-title" onClick={handleChange}>
                  {' '}
                  {/* eslint-disable-line */}
                  <span>{dictionary.gameMyAccount}</span>
                  <IcomoonIcon className={isOpen ? 'arrow open' : 'arrow'} icon="triangle" size={7} />
                </div>
                {isOpen && (
                  <ul className="account-menu">
                    <li>
                      <Link to="/profile/">{dictionary.gameMyProfile}</Link>
                    </li>
                    <li>
                      <Link
                        to="/withdraw/"
                        // onClick={openWithdraw}
                      >
                        {dictionary.gameWithdrawMoney}
                      </Link>
                    </li>
                    <li>
                      <Link to="/freespin-terms-and-conditions/">{dictionary.gameTerms}</Link>
                    </li>
                    <li>
                      <button onClick={logOut} type="button">
                        {dictionary.gameLogOut}
                      </button>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        )}
      </header>
    </HeaderWrapper>
  );
};

export default HeaderAccount;
