import React from 'react';
import styled from '@emotion/styled';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import { wrapper, color, breakpoints } from '../../utils/style';
import GameIframe from '../../components/Content/Games/Iframe';
import CommentsStaticLoadmore from '../../components/Content/Withdraw/CommentsStaticLoadmore';
import HeaderAccount from '../../components/Content/Games/HeaderAccount';

const WrapperDesktop = styled('div')`
  max-width: 1260px;
  @media (max-width: ${breakpoints.sm}) {
    padding: 0;
  }
`;

const WrapperDarkRed = styled('div')`
  background: ${color.darkRed};
  @media (max-width: ${breakpoints.md}) {
    margin: 0 0 -5px;
  }
`;

const GameWrapper = styled('div')`
  ${wrapper};
  margin-bottom: 40px;
`;

const Game = ({ pageContext: { breadcrumbs, page_title, name, code } }) => (
  <Layout breadcrumbs={breadcrumbs} page_title={page_title}>
    <SEO title={name} description={name} pathname={`/${code}/`} />
    <WrapperDesktop>
      <GameWrapper>
        <HeaderAccount page_title={page_title} />
        <GameIframe game_code={code} />
      </GameWrapper>
      <CommentsStaticLoadmore />
    </WrapperDesktop>
    <WrapperDarkRed>
      <WrapperDesktop />
    </WrapperDarkRed>
  </Layout>
);

export default Game;
