import React, { useEffect, useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const GameImg = ({ imgType }) => {
  const gameTypes = ['SGFourDivineBeasts', 'SGBirdOfThunder', 'SGFaCaiShen', 'SG5LuckyLions', 'SGWaysOfFortune'];
  const [game, setGame] = useState(imgType);
  useEffect(() => (gameTypes.includes(imgType) ? setGame(imgType) : setGame('SGWaysOfFortune')), [gameTypes, imgType]);
  return (
    <StaticQuery
      query={graphql`
        query {
          SGFourDivineBeasts: file(relativePath: { eq: "games/four-divine-beasts.png" }) {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          SGBirdOfThunder: file(relativePath: { eq: "games/bird-of-thunder.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          SGFaCaiShen: file(relativePath: { eq: "games/fa-cai-shen.png" }) {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          SG5LuckyLions: file(relativePath: { eq: "games/5-lucky-lions.png" }) {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          SGWaysOfFortune: file(relativePath: { eq: "games/ways-of-fortune.png" }) {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        <div className="game-banner">
          {data[game]?.childImageSharp.fluid && <Img alt={imgType} fluid={data[game].childImageSharp.fluid} />}
        </div>
      )}
    />
  );
};
export default GameImg;
