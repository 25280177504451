import React from 'react';
import styled from '@emotion/styled';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { breakpoints, color, flex, gradient, resetButton } from '../../../utils/style';
import dictionary from '../../../utils/dictionary';
import { getLocalFile, validateLink } from '../../../utils/system';

const GameAds = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 30px 0;
  user-select: none;
`;

const GameAd = styled('div')`
  width: 24%;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-bottom: 15px;
  .show-more,
  .coming-soon {
    width: 120px;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -60px;
    display: none;
  }
  .coming-soon {
    background: transparent;
    color: ${color.white};
    padding: 12px;
    text-align: center;
    pointer-events: none;
    width: 150px;
    margin: -25px 0 0 -75px;
  }
  .ribbon {
    position: absolute;
    top: 12%;
    left: -15px;
    z-index: 1;
    padding: 5px 10px;
    background: ${color.red};
    font-style: italic;
    font-weight: bold;
    &::after {
      content: '';
      width: 0;
      height: 0;
      border-top: 28px solid ${color.red};
      border-right: 15px solid transparent;
      position: absolute;
      right: -15px;
      top: 0;
    }
    &::before {
      content: '';
      width: 0;
      height: 0;
      border-bottom: 28px solid ${color.red};
      border-left: 15px solid transparent;
      position: absolute;
      left: -15px;
      top: 0;
    }
  }
  &:hover,
  &:active {
    .ribbon {
      opacity: 0.8;
    }
    .coming-soon,
    .show-more {
      display: inline-block;
    }
  }
  
  a,
  .a {
    display: block;
    position: relative;
    
    &:hover,
    &:active {
      & > .background {
        display: flex;
      }
    }
    
    & > .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.7);
      ${flex};
      display: none;
      
      & > button {
        ${resetButton};
        width: 132px;
        height: 44px;
        background: ${gradient.blueAzureBlue};
        background-size: 200%;
        transition: 0.3s;
        border-radius: 20px;
        
        font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${color.white};
        
        &:hover {
          background-position: right center;
        }
      }
    }
  }

  .gatsby-image-wrapper {
    border-radius: 5px;
    height: 250px;
    margin-bottom: 5px;
    @media (max-width: ${breakpoints.sm}) {
      height: 140px;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    .coming-soon {
      font-size: 12px;
      margin: -10px 0 0 -57px;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    width: 47%;
    margin: 1.2%;
    .coming-soon {
      font-size: 0.8rem;
    }
  }
`;

const PlayForFreeGames = ({ exclCode }) => (
  <StaticQuery
    query={graphql`
        query {
          allWordpressPage(filter: { slug: { eq: "home" } }) {
            edges {
              node {
                acf {
                  play_for_free_title
                  play_for_free_video_cover {
                    alt_text
                    title
                    path
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 620) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  gatsby_freespin_games {
                    alt_text
                    title
                    path
                    acf {
                      slide_url
                      slide_title
                      slide_description
                    }
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 400) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
    render={(data) => {
      const { allWordpressPage: { edges } } = data;
      const { node: { acf: { gatsby_freespin_games } } } = edges[0];
      return (
        <GameAds>
          {gatsby_freespin_games?.map((item) => {
            const { acf, path, localFile = {}, alt_text } = item;
            const { slide_url, slide_description, slide_title } = acf;
            if (
              slide_description &&
              slide_url &&
              slide_url !== '#' &&
              !slide_url.includes(exclCode) &&
              slide_title !== dictionary.comingSoon
            ) {
              return (
                <GameAd key={path}>
                  <a
                    href={validateLink(slide_url)}
                    title={alt_text}
                  >
                    {
                      getLocalFile(localFile) &&
                      <Img fluid={getLocalFile(localFile)} alt={alt_text || 'game image'} />
                    }
                    <div className="background">
                      <button>{dictionary.gameTitleBlueButton}</button>
                    </div>
                  </a>
                </GameAd>
              );
            }
            return null;
          })}
        </GameAds>
      );
    }}
  />
);
export default PlayForFreeGames;
